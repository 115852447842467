<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loadingState">
                <b-row>
                  <b-col lg="12" sm="12">
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                      <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                        <b-col lg="12" sm="12">
                          <div class="mt-3">
                            <b-table-simple striped bordered small>
                                <b-tr>
                                  <b-th>{{ $t('dae_grant_allocation_distribution.fiscal_year') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.fiscal_year_bn : demmandDetailsData.fiscal_year }}</b-td>
                                  <b-th>{{ $t('dae_grant_allocation_distribution.season') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.season_bn : demmandDetailsData.seasson }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th>{{ $t('dae_config.circular_type') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.circular_type_bn : demmandDetailsData.circular_type }}</b-td>
                                  <b-th>{{ $t('dae_grant_allocation_distribution.circular') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.circular_bn : demmandDetailsData.circular }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th>{{ $t('dae_grant_allocation_distribution.project') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.project_bn : demmandDetailsData.project }}</b-td>
                                  <b-th>{{ $t('globalTrans.division') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn') ? demmandDetailsData.division_name_bn : demmandDetailsData.division_name }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th>{{ $t('globalTrans.district') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn') ? demmandDetailsData.district_name_bn : demmandDetailsData.district_name }}</b-td>
                                  <b-th>{{ $t('globalTrans.upazila') }}</b-th>
                                  <b-td colspan="3">{{ ($i18n.locale=='bn') ? demmandDetailsData.upazilla_name_bn : demmandDetailsData.upazilla_name }}</b-td>
                                </b-tr>
                            </b-table-simple>
                            <b-row>
                              <b-col lg="12" sm="12">
                                <div style="font-size:14px; background-color: #337982; padding:6px">
                                  <h5 class="text-white text-center">
                                  {{ $t('dae_grant_allocation_distribution.remaining_allocation_info') }}
                                  </h5>
                                </div>
                                <div class="table-responsive">
                                  <b-table-simple striped bordered small class="mt-2">
                                    <b-thead>
                                      <b-tr>
                                        <b-th class="text-center" rowspan="2">{{ $t('dae_grant_allocation_distribution.cash_tk') }}</b-th>
                                        <b-th class="text-center" :colspan="allocationInfo.crops.length ? allocationInfo.crops.length : 1">{{ $t('dae_grant_allocation_distribution.crop_name') }} ({{ $t('dae_grant_allocation_distribution.kg') }})</b-th>
                                        <b-th class="text-center" :colspan="allocationInfo.materials.length ? allocationInfo.materials.length : 1">{{ $t('dae_grant_allocation_distribution.fertilizer') }} ({{ $t('dae_grant_allocation_distribution.kg') }})</b-th>
                                      </b-tr>
                                      <b-tr>
                                        <template v-if="allocationInfo.crops.length">
                                          <b-th v-for="(crop, cropIndex) in allocationInfo.crops" class="text-center" :key="100 + cropIndex">{{ localeLang === 'en' ? crop.crop_name : crop.crop_name_bn }}</b-th>
                                        </template>
                                        <template v-else>
                                          <b-th></b-th>
                                        </template>
                                        <template v-if="allocationInfo.materials.length">
                                          <b-th v-for="(material, materialIndex) in allocationInfo.materials" class="text-right" :key="200 + materialIndex">{{ localeLang === 'en' ? material.material_name : material.material_name_bn }}</b-th>
                                        </template>
                                        <template v-else>
                                          <b-th></b-th>
                                        </template>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                      <b-tr>
                                        <b-td class="text-right align-top">{{ $n(allocationInfo.cashTk) }}</b-td>
                                        <template v-if="allocationInfo.crops.length">
                                          <b-td v-for="(crop, cropIndex) in allocationInfo.crops" class="text-right" :key="1000 + cropIndex">{{ $n(crop.total_quantity) }}</b-td>
                                        </template>
                                        <template v-else>
                                          <b-td></b-td>
                                        </template>
                                        <template v-if="allocationInfo.materials.length">
                                          <b-td v-for="(material, materialIndex) in allocationInfo.materials" class="text-right" :key="2000 + materialIndex">{{ $n(material.total_quantity) }}</b-td>
                                        </template>
                                        <template v-else>
                                          <b-td></b-td>
                                        </template>
                                      </b-tr>
                                    </b-tbody>
                                  </b-table-simple>
                                </div>
                                <div style="font-size:14px; background-color: #337982; padding:6px">
                                  <h5 class="text-white text-center">
                                    {{ $t('dae_grant_allocation_distribution.demand_summary') }}
                                  </h5>
                                </div>
                                <div v-for="(detail, indx) in demand_details" :key="indx" class="mt-1">
                                  <h5>{{ $t('globalTrans.union') }}: {{ $i18n.locale === 'bn' ? detail.union_name_bn : detail.union_name }} </h5>
                                  <div class="table-responsive">
                                    <b-table-simple striped bordered small class="mt-2">
                                      <b-thead>
                                      <b-tr>
                                        <b-th rowspan="3">{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th rowspan="3">{{ $t('common_config.farmer_name') }}</b-th>
                                        <b-th rowspan="3">{{ $t('common_config.farmer_father_name') }}</b-th>
                                        <b-th rowspan="3">{{ $t('common_config.nid_no') }}</b-th>
                                        <b-th rowspan="3">{{ $t('common_config.mobile_no') }}</b-th>
                                        <b-th :colspan="parseInt(policyCrops.length ? policyCrops.length : 1) + parseInt(policyMaterials.length ? policyMaterials.length : 1) + 1" class="text-center">{{ $t('dae_grant_allocation_distribution.allocation_info') }}</b-th>
                                        <b-th rowspan="3">
                                          <strong>
                                          <b-form-checkbox
                                              id="checkbox-all"
                                              v-model="checkUncheckAll"
                                              name="checkbox-all"
                                          >
                                            {{ $t('dae_grant_allocation_distribution.check_all') }}
                                          </b-form-checkbox>
                                          </strong>
                                        </b-th>
                                      </b-tr>
                                       <b-tr>
                                         <b-th class="text-center" rowspan="2">{{ $t('dae_grant_allocation_distribution.cash_tk') }}</b-th>
                                          <b-th :colspan="policyCrops.length ? policyCrops.length : 1" class="text-center">{{ $t('dae_grant_allocation_distribution.crop_name') }} ({{ $t('dae_grant_allocation_distribution.kg') }})</b-th>
                                          <b-th :colspan="policyMaterials.length ? policyMaterials.length : 1" class="text-center">{{ $t('dae_grant_allocation_distribution.fertilizer') }} ({{ $t('dae_grant_allocation_distribution.kg') }})</b-th>
                                        </b-tr>
                                        <b-tr>
                                          <b-th v-for="(pCrop,pCropIndex) in policyCrops" :key="pCropIndex" class="text-right">
                                            {{ ($i18n.locale === 'bn') ? pCrop.crop_name_bn : pCrop.crop_name }} ({{ $n(getPolicyCropAmount(pCrop.crop_id)) }})
                                          </b-th>
                                          <template v-if="policyMaterials.length">
                                             <b-th v-for="(pMatr,pMatrIndex) in policyMaterials" :key="pMatrIndex" class="text-center">
                                                {{ ($i18n.locale === 'bn') ? pMatr.material_name_bn : pMatr.material_name }} ({{ $n(getPolicyMaterialAmount(pMatr.material_id)) }})
                                            </b-th>
                                          </template>
                                          <template v-else>
                                            <b-th></b-th>
                                          </template>
                                        </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                      <b-tr v-for="(detailItem,index) in detail.farmer_info" :key="index">
                                          <b-td>{{ $n(index+1) }}</b-td>
                                          <b-td>{{ ($i18n.locale === 'bn') ? detailItem.farmer_name_bn : detailItem.farmer_name }}</b-td>
                                          <b-td>{{ ($i18n.locale === 'bn') ? detailItem.father_name_bn : detailItem.father_name }}</b-td>
                                          <b-td>{{ $n(detailItem.nid_no, { useGrouping: false }) }}</b-td>
                                          <b-td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(detailItem.mobile_no, { useGrouping: false } ) }}</b-td>
                                          <b-td class="text-right">{{ typeof detailItem.circular.distribution_policy !== 'undefined' ? $n(detailItem.circular.distribution_policy.amount_per_farmer) : $n(0) }}</b-td>
                                            <template v-if="detailItem.crops.length">
                                             <b-td v-for="(sinPCrop,sinPCropIndex) in detailItem.crops" :key="sinPCropIndex+100" class="text-center">
                                               <b-form-checkbox
                                                   :id="'checkbox-crop-detail-'+ indx +'-' + index + '-crop-' + sinPCropIndex"
                                                   v-model="sinPCrop.is_checked"
                                                   :name="'checkbox-crop-detail-'+ indx +'-' + index + '-crop-' + sinPCropIndex"
                                                   @change="setCropQty(indx, index, sinPCropIndex)"
                                               >
                                               </b-form-checkbox>
                                              </b-td>
                                            </template>
                                            <template v-else>
                                              <b-td></b-td>
                                            </template>
                                              <template v-if="detailItem.material.length">
                                              <b-td v-for="(sinPMaterial,sinPMaterialIndex) in detailItem.material" :key="sinPMaterialIndex+200" class="text-center">
                                                <b-form-checkbox
                                                    :id="'checkbox-material-detail-'+ indx +'-' + index + '-material-' + sinPMaterialIndex"
                                                    v-model="sinPMaterial.is_checked"
                                                    :name="'checkbox-material-detail-'+ indx +'-' + index + '-material-' + sinPMaterialIndex"
                                                    @change="setMaterialQty(indx, index, sinPMaterialIndex)"
                                                >
                                                </b-form-checkbox>
                                              </b-td>
                                              </template>
                                              <template v-else>
                                                <b-td></b-td>
                                              </template>
                                            <b-td style="text-align: center; v-align: middle;">
                                              <span>
                                                <b-form-checkbox
                                                    :id="'detail-'+ indx + '-' + index"
                                                    v-model="detailItem.is_check"
                                                    :name="'detail-'+ indx + '-' + index"
                                                >
                                              </b-form-checkbox>
                                              </span>
                                          </b-td>
                                      </b-tr>
                                      </b-tbody>
                                      <b-tfoot>
                                        <b-tr>
                                          <b-th colspan="5">{{ $t('globalTrans.total') }}</b-th>
                                          <b-th class="text-right">{{ $n(detail.union_total.cash_tk) }}</b-th>
                                          <b-th v-for="(sinPCrop,sinPCropIndex) in policyCrops" :key="sinPCropIndex+300" class="text-right">
                                            {{ $n(detail.union_total.crops[sinPCrop.crop_id]) }}
                                          </b-th>
                                          <b-th v-for="(sinPMaterial,sinPMaterialIndex) in policyMaterials" :key="sinPMaterialIndex+300" class="text-right">
                                            {{ $n(detail.union_total.materials[sinPMaterial.material_id]) }}
                                          </b-th>
                                          <b-th></b-th>
                                        </b-tr>
                                      </b-tfoot>
                                    </b-table-simple>
                                  </div>
                                </div>
                              </b-col>
                            </b-row>
                             <div class="row mb-3">
                              <div class="col-sm-3"></div>
                                <div class="col text-right">
                                     <b-button type='button' variant="info" @click="forwardPageShow = !forwardPageShow" class="mr-2">{{ $t('dae_grant_allocation_distribution.forward')}}</b-button>
                                    &nbsp;
                                    <b-button type="submit" variant="primary" class="mr-2" @click="approve">{{ $t('budget.approve') }}</b-button>
                                    <b-button type="submit" variant="warning" class="mr-2" @click="reject">{{ $t('dae_grant_allocation_distribution.reject') }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </div>
                            <div v-if="forwardPageShow" class="mt-3">
                              <b-row>
                                <b-col md="6" lg="6">
                                  <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="3"
                                        label-for="office_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('globalTrans.office') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="form.office_id"
                                          :options="officeList"
                                          id="office_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col md="6" lg="6">
                                  <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="3"
                                        label-for="designation_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{ $t('budget.designation') }} <span class="text-danger">*</span>
                                        </template>
                                    <b-form-select
                                        plain
                                        v-model="form.designation_id"
                                        :options="designationList"
                                        id="designation_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col md="6" lg="6">
                                  <ValidationProvider name="Officer" vid="receiver_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="3"
                                        label-for="receiver_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{ $t('budget.officer') }} <span class="text-danger">*</span>
                                        </template>
                                    <b-form-select
                                        plain
                                        v-model="form.receiver_id"
                                        :options="officerList"
                                        id="receiver_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{ officerLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col md="6" lg="6">
                                  <ValidationProvider name="Comment" vid="comment" rules="required|max:200">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="3"
                                        label-for="comment"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{ $t('budget.comment') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-textarea
                                            plain
                                            v-model="form.comment"
                                            id="comment"
                                            placeholder="Maximum 200 word.."
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-textarea>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col md="9" lg="9">
                                </b-col>
                                <b-col md="3" lg="3" class="text-right">
                                  <div class="mb-3">
                                      <b-button type="submit" @click="forward" variant="primary" class="mr-2">{{ $t('globalTrans.save')}}</b-button>
                                      &nbsp;
                                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                            <p class="text-dark"><strong>{{ $t('globalTrans.note') }}: </strong>{{ $t('dae_grant_allocation_distribution.demand_request_note') }}</p>

                          </div>
                        </b-col>
                      </b-form>
                    </ValidationObserver>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { designationWiseUser, farmerDataApi, upazillaDemandReqList, demandApproveForward, subsidyDemandCorpList, upazillaAllocationList } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  async created () {
    const tmp = this.getformDataData()
    this.getCropInfo(tmp.id)
    this.demmandDetailsData = this.getformDataData()
    this.farAddressData = this.getFarmerInformation(tmp.nid_no)
    this.getForwardDemandList(this.item.circular_id, this.item.upazilla_id)
    this.officeIdList = []
    this.getOfficeIdList(this.authUser.office_id)
    this.getAssignDesignationList()
    await this.getUpazilaAllocationInfo(this.item.circular_id)
    this.form.circular_id = this.item.circular_id
  },
  mounted () {
    this.officeList = this.officeIdList.map(officeId => {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === officeId)
      return { value: office.value, text: this.$i18n.locale === 'en' ? office.text_en : office.text_bn }
    })
  },
  data () {
    return {
      demand_details: [],
      demmandDetailsData: {},
      farAddressData: {},
      slOffset: 1,
      crop_names: [],
      loadingState: false,
      isForward: false,
      policyCrops: [],
      policyMaterials: [],
      approveCheckIds: [],
      rejectCheckIds: [],
      grandLandTotal: 0,
      officerLoading: false,
      officerList: [],
      approveBtnName: this.$t('dae_grant_allocation_distribution.submit'),
      forwardPageShow: false,
      form: {
        fiscal_year_id: 0,
        circular_id: 0,
        office_id: 0,
        status: 4,
        demand_id: 0,
        cropDetails: [],
        materialDetails: [],
        sub_demmand_id: this.id,
        designation_id: 0,
        sender_id: this.$store.state.Auth.authUser.user_id,
        receiver_id: 0,
        comment: '',
        allContent: []
      },
      officeIdList: [],
      officeList: [],
      assignDesignationList: [],
      designationList: [],
      checkUncheckAll: false,
      demand_details_all: [],
      allocationInfo: {
        cashTk: 0,
        crops: [],
        materials: []
      },
      checkedCrops: {},
      checkedMaterials: {}
    }
  },
  watch: {
    'form.office_id': function (newValue) {
      this.getDesignationList(newValue)
    },
    'form.designation_id': function (newValue) {
      this.getOfficerList(newValue)
    },
    checkUncheckAll: function (newValue) {
      if (newValue) {
        this.demand_details.map(item => {
          item.farmer_info.map(farmerItem => {
            farmerItem.is_check = true
          })
        })
      } else {
        this.demand_details.map(item => {
          item.farmer_info.map(farmerItem => {
            farmerItem.is_check = false
          })
        })
      }
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    },
    authUser () {
      return this.$store.state.Auth.authUser
    }
  },
  methods: {
    getCropUnitType (unit) {
      if (unit === 1) {
        return this.$t('dae_grant_allocation_distribution.kg')
      }
    },
    getMaterialUnitType (unit) {
      if (unit === 1) {
        return this.$t('dae_grant_allocation_distribution.kg')
      }
    },
    forwarded (item) {
      this.forwardId = item.id
    },
    forward () {
      this.form.status = 3
    },
    approve () {
      this.form.status = 4
    },
    reject () {
      this.form.status = 5
    },
    getformDataData () {
      return JSON.parse(JSON.stringify(this.item))
    },
    getCropName (cropId) {
      const objectData = this.$store.state.incentiveGrant.commonObj.cropList.find(item => item.value === cropId)
      if (this.$i18n.locale === 'bn') {
        return objectData.text_bn
      } else {
        return objectData.text_en
      }
    },
    async getOfficerList (designationId) {
        this.officerLoading = true
        await RestApi.getData(incentiveGrantServiceBaseUrl, `${designationWiseUser}/${this.form.office_id}/${designationId}`)
        .then(response => {
            if (response.success) {
                const data = response.data
                this.officerList = data.map(item => {
                    return Object.assign({ value: item.user_id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name })
                })
            } else {
                this.officerList = []
            }
            this.officerLoading = false
        })
    },
    async getFarmerInformation (nid) {
      this.loadingState = true
      const params = Object.assign({}, { nid: nid })
      await RestApi.getData(incentiveGrantServiceBaseUrl, farmerDataApi, params).then(response => {
        if (response.success) {
          this.demmandDetailsData.farmer_data.gender = response.data.gender_id === '1' ? 'male11' : 'Female'
          const divisionObject = this.$store.state.commonObj.divisionList.find(division => division.value === response.data.division_id)
          this.demmandDetailsData.division_id = response.data.division_id
          this.farAddressData.division_name = divisionObject !== undefined ? this.$i18n.locale === 'bn' ? divisionObject.text_bn : divisionObject.text_en : ''
          const districtObject = this.$store.state.commonObj.districtList.find(district => district.value === response.data.district_id)
          this.demmandDetailsData.farmer_data.district_id = response.data.district_id
          this.demmandDetailsData.farmer_data.district_name = districtObject !== undefined ? this.$i18n.locale === 'bn' ? districtObject.text_bn : districtObject.text_en : ''
          const upazillaObject = this.$store.state.commonObj.upazilaList.find(upazillaItem => upazillaItem.value === response.data.upazilla_id)
          this.demmandDetailsData.farmer_data.upazilla = upazillaObject !== undefined ? this.$i18n.locale === 'bn' ? upazillaObject.text_bn : upazillaObject.text_en : ''
          const unionObject = this.$store.state.commonObj.unionList.find(unionItem => unionItem.value === response.data.union_id)
          this.demmandDetailsData.farmer_data.union = unionObject !== undefined ? this.$i18n.locale === 'bn' ? unionObject.text_bn : unionObject.text_en : ''
          const wardObject = this.$store.state.commonObj.wardList.find(wardItem => wardItem.value === response.data.ward_id)
          this.demmandDetailsData.farmer_data.ward_no = wardObject !== undefined ? this.$i18n.locale === 'bn' ? wardObject.text_bn : wardObject.text_en : ''
          const perDistrictObject = this.$store.state.commonObj.districtList.find(perDistrict => perDistrict.value === response.data.per_district_id)
          this.demmandDetailsData.farmer_data.per_district_id = perDistrictObject !== undefined ? this.$i18n.locale === 'bn' ? perDistrictObject.text_bn : perDistrictObject.text_en : ''
          const perUpazillaObject = this.$store.state.commonObj.upazilaList.find(perUpazillaItem => perUpazillaItem.value === response.data.per_upazilla_id)
          this.demmandDetailsData.farmer_data.per_upazilla = perUpazillaObject !== undefined ? this.$i18n.locale === 'bn' ? perUpazillaObject.text_bn : perUpazillaObject.text_en : ''
          const perUnionObject = this.$store.state.commonObj.unionList.find(perUnionItem => perUnionItem.value === response.data.per_union_id)
          this.demmandDetailsData.farmer_data.per_union = perUnionObject !== undefined ? this.$i18n.locale === 'bn' ? perUnionObject.text_bn : perUnionObject.text_en : ''
          const perWardObject = this.$store.state.commonObj.wardList.find(perWardItem => perWardItem.value === response.data.per_ward_id)
          this.demmandDetailsData.farmer_data.per_ward_no = perWardObject !== undefined ? this.$i18n.locale === 'bn' ? perWardObject.text_bn : perWardObject.text_en : ''
        }
      })
      // this.loadingState = false
      return this.demmandDetailsData
    },
    async getForwardDemandList (circularId, upazillaId) {
      this.loadingState = true
      const params = Object.assign({}, { circular_id: this.item.circular_id, fiscal_year_id: this.item.fiscal_year_id, upazilla_id: upazillaId, receiver_id: this.$store.state.Auth.authUser.user_id })
      await RestApi.getData(incentiveGrantServiceBaseUrl, upazillaDemandReqList, params).then(response => {
        if (response.success) {
          this.policyCrops = response.existCropList
          this.policyMaterials = response.existMaterialList
          this.policyCrops.forEach(item => {
            this.checkedCrops[item.crop_id] = 0
          })
          this.policyMaterials.forEach(item => {
            this.checkedMaterials[item.material_id] = 0
          })
          this.demand_details = this.getDemandRelatinalData(response.data, response.existCropList)
        }
        // this.loadingState = false
      })
    },
    getDemandRelatinalData (data, existCrops) {
      this.demand_details_all = []
      const listData = data.map((item, index) => {
        const union = this.$store.state.commonObj.unionList.find(un => un.value === item.union_id)
        const unionObj = {}
        unionObj.union_name = union !== undefined ? union.text_en : ''
        unionObj.union_name_bn = union !== undefined ? union.text_bn : ''
        const unionTotalRowItem = {
          cash_tk: 0,
          crops: {},
          materials: {}
        }
        this.policyCrops.forEach(item => {
          unionTotalRowItem.crops[item.crop_id] = 0
        })
        this.policyMaterials.forEach(item => {
          unionTotalRowItem.materials[item.material_id] = 0
        })
        item.farmer_info = item.farmer_info.map(farmer => {
          const deReqObj = {
            far_general_info_id: farmer.far_general_info_id,
            subs_demand_id: farmer.id,
            land_quantity: farmer.land_quantity,
            farmer_name: farmer.farmer_data.name,
            farmer_name_bn: farmer.farmer_data.name_bn,
            father_name: farmer.farmer_data.father_name,
            father_name_bn: farmer.farmer_data.father_name_bn,
            nid_no: farmer.farmer_data.nid_no,
            mobile_no: farmer.farmer_data.mobile_no,
            demandCollectioCropList: farmer.demand_collection_details,
            crops: [],
            material: [],
            is_check: false
          }
          this.grandLandTotal += item.land_quantity ? (parseFloat(item.land_quantity)) : 0
            this.policyCrops.map(tmp => {
              const approveCrop = farmer.demand_crop_approval.find(obj => obj.crop_id === tmp.crop_id)
              const isChecked = typeof approveCrop !== 'undefined'
              if (isChecked) {
                this.checkedCrops[tmp.crop_id] += 1
                unionTotalRowItem.crops[tmp.crop_id] += tmp.quantity
              }
            deReqObj.crops.push({ id: tmp.crop_id, quantity: tmp.quantity, is_checked: isChecked })
          })
          this.policyMaterials.map(tmpMt => {
            const approveMaterial = farmer.demand_material_approval.find(obj => obj.material_id === tmpMt.material_id)
            const isChecked = typeof approveMaterial !== 'undefined'
            if (isChecked) {
              this.checkedMaterials[tmpMt.material_id] += 1
              unionTotalRowItem.materials[tmpMt.material_id] += tmpMt.quantity
            }
            deReqObj.material.push({ id: tmpMt.material_id, quantity: tmpMt.quantity, is_checked: isChecked })
          })
          unionTotalRowItem.cash_tk += typeof farmer.circular.distribution_policy !== 'undefined' ? parseFloat(farmer.circular.distribution_policy.amount_per_farmer) : 0
          const farmerObjCustom = Object.assign({}, farmer, deReqObj)
          this.demand_details_all.push(farmerObjCustom)
          return Object.assign({}, farmer, deReqObj)
        })
        return Object.assign({}, item, unionObj, { union_total: unionTotalRowItem })
      })
      this.form.cropDetails = listData
      return listData
    },
    async getCropInfo (demand) {
      this.loadingState = true
      const params = Object.assign({}, { demand_id: demand })
      await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyDemandCorpList, params).then(response => {
        if (response.success) {
          this.crop_names = response.data.map(item => {
            return item.crop_name
          })
        }
      })
      // this.loadingState = false
      return true
    },
    async saveData () {
      this.loadingState = true
      Object.assign(this.form, { allContent: this.demand_details })
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, demandApproveForward, this.form)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loadingState = false
      if (result.success) {
        this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-5')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
    },
    getOfficeIdList (officeId) {
      this.officeIdList.push(officeId)
      const office = this.$store.state.commonObj.officeList.find(item => item.value === officeId)
      if (office.parent_office_id) {
        this.getOfficeIdList(office.parent_office_id)
      }
      return this.officeIdList
    },
    async getAssignDesignationList () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(commonServiceBaseUrl, 'assign-designation/list-all')
      if (result.success) {
        this.assignDesignationList = result.data
      } else {
        this.assignDesignationList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    getDesignationList (officeId) {
      const assignDesignationList = this.assignDesignationList.filter(item => item.office_id === officeId)
      this.designationList = assignDesignationList.map(item => {
        const designation = this.$store.state.commonObj.designationList.find(obj => obj.value === item.designation_id)
        return designation
      })
    },
    getPolicyCropAmount (cropId) {
      const crop = this.policyCrops.find(item => item.crop_id === cropId)
      return typeof crop !== 'undefined' ? crop.quantity : 0
    },
    getPolicyMaterialAmount (materialId) {
      const material = this.policyMaterials.find(item => item.material_id === materialId)
      return typeof material !== 'undefined' ? material.quantity : 0
    },
    async getUpazilaAllocationInfo (circularId) {
      this.loadingState = true
      const params = Object.assign({}, { circular_id: circularId })
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, upazillaAllocationList, params)
      if (result.success) {
        // this.allocationInfo = {
        //   cashTk: result.data.cashTk,
        //   crops: result.data.crops,
        //   materials: result.data.materials
        // }
        this.allocationInfo = result.data
        this.allocationInfo.crops = this.allocationInfo.crops.map(item => {
          const totalCheckedItem = this.checkedCrops[item.crop_id]
          let totalQty
          if (totalCheckedItem) {
            const policyCrop = this.policyCrops.find(obj => obj.crop_id === item.crop_id)
            const reducedQty = parseFloat(policyCrop.quantity) * totalCheckedItem
            totalQty = item.total_quantity - reducedQty
          } else {
            totalQty = item.total_quantity
          }
          return Object.assign({}, item, { total_quantity: totalQty })
        })
        this.allocationInfo.materials = this.allocationInfo.materials.map(item => {
          const totalCheckedItem = this.checkedMaterials[item.material_id]
          let totalQty
          if (totalCheckedItem) {
            const policyCrop = this.policyMaterials.find(obj => obj.material_id === item.material_id)
            const reducedQty = parseFloat(policyCrop.quantity) * totalCheckedItem
            totalQty = item.total_quantity - reducedQty
          } else {
            totalQty = item.total_quantity
          }
          return Object.assign({}, item, { total_quantity: totalQty })
        })
      } else {
        this.allocationInfo = {
          cashTk: 0,
          crops: [],
          materials: []
        }
      }
      this.loadingState = false
    },
    setCropQty (unionIndex, farmerIndex, cropIndex) {
      const crop = this.demand_details[unionIndex].farmer_info[farmerIndex].crops[cropIndex]
      const demandDetailsItem = this.demand_details[unionIndex]
      if (crop.is_checked) {
        return this.allocationInfo.crops.map(item => {
          if (item.crop_id === crop.id) {
            if (parseFloat(crop.quantity) > item.total_quantity) {
              this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'This crop is not available',
                color: '#D6E09B'
              })
              crop.is_checked = false
            } else {
              item.total_quantity -= parseFloat(crop.quantity)
              demandDetailsItem.union_total.crops[crop.id] += parseFloat(crop.quantity)
            }
          }
        })
      } else {
        return this.allocationInfo.crops.map(item => {
          if (item.crop_id === crop.id) {
            item.total_quantity += parseFloat(crop.quantity)
            demandDetailsItem.union_total.crops[crop.id] -= parseFloat(crop.quantity)
          }
        })
      }
    },
    setMaterialQty (unionIndex, farmerIndex, materialIndex) {
      const material = this.demand_details[unionIndex].farmer_info[farmerIndex].material[materialIndex]
      const demandDetailsItem = this.demand_details[unionIndex]
      if (material.is_checked) {
        // return this.allocationInfo.materials.map(item => {
        //   if (item.material_id === material.id) {
        //     item.total_quantity -= parseFloat(material.quantity)
        //   }
        // })
        this.allocationInfo.materials = this.allocationInfo.materials.map(item => {
          let totalQty
          if (item.material_id === material.id) {
            if (parseFloat(material.quantity) > item.total_quantity) {
              this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'This material is not available',
                color: '#D6E09B'
              })
              totalQty = item.total_quantity
              material.is_checked = false
            } else {
              totalQty = item.total_quantity - parseFloat(material.quantity)
              demandDetailsItem.union_total.materials[material.id] += parseFloat(material.quantity)
            }
          } else {
            totalQty = item.total_quantity
          }
          return Object.assign({}, item, { total_quantity: totalQty })
        })
      } else {
        // return this.allocationInfo.materials.map(item => {
        //   if (item.material_id === material.id) {
        //     item.total_quantity += parseFloat(material.quantity)
        //   }
        // })
        this.allocationInfo.materials = this.allocationInfo.materials.map(item => {
          let totalQty
          if (item.material_id === material.id) {
            totalQty = item.total_quantity + parseFloat(material.quantity)
            demandDetailsItem.union_total.materials[material.id] -= parseFloat(material.quantity)
          } else {
            totalQty = item.total_quantity
          }
          return Object.assign({}, item, { total_quantity: totalQty })
        })
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
