// Project Wise Subsidy Mapping Api Routes...
const grantAllocation = 'dae/grant-allocation-distribution/demand-collection/'
export const subsidyDemandCollectionList = grantAllocation + 'list'
export const subsidyDemandCollectionStore = grantAllocation + 'store'
export const subsidyDemandCollectionUpdate = grantAllocation + 'update'
export const subsidyDemandCollectionStatus = grantAllocation + 'toggle-status'
export const subsidyDemandCollectionDestroy = grantAllocation + 'destroy'
export const subsidyDemandCollectionForward = grantAllocation + 'forward'
export const designationWiseUser = '/dae/subsidy/bill-prepare/get-user'
export const subsidyDemandCollectionRequestList = grantAllocation + 'request-list'
export const demandReqList = grantAllocation + 'union-wise-request-list-details'
export const demandApproveForward = grantAllocation + 'all-approve-and-forward'
export const subsidyDemandCollectionApprovedList = grantAllocation + 'approved-list'
export const demandUnionWiseApprovedListDetails = grantAllocation + 'union-wise-approved-list-details'
export const subsidyDemandCollectionReceive = grantAllocation + 'receive'
export const subsidyDemandCollectionReceivedList = grantAllocation + 'received-list'
export const demandRcvList = grantAllocation + 'union-wise-receive-list-details'
export const upazillaDdemandRcvList = grantAllocation + 'upazilla-wise-receive-list-details'
export const upazillaDemandReqList = grantAllocation + 'upazilla-wise-request-list-details'
export const upazillaDemandApproveDetails = grantAllocation + 'upazilla-wise-approve-details'
export const upazillaAllocationList = grantAllocation + 'upazilla-allocation-info'

export const subsidyDemandCorpList = grantAllocation + 'crop-list'
export const farmerDataApi = grantAllocation + 'farmer-data'
export const circularDataApi = grantAllocation + 'circular-data'

// Subsidy Allocation Approval Api Routes...
export const subsidyDistributionApprovalList = grantAllocation + 'subsidy-distribution-approval/list'
export const subsidyDistributionApprovalUpdate = grantAllocation + 'subsidy-distribution-approval/update'

const grantAlloDistribution = 'dae/grant-allocation-distribution/'
export const distributionPendingList = grantAlloDistribution + 'distribution-pending-list'
export const singleDistirbutionPendingDetails = grantAlloDistribution + 'single-distribution-pending-details'
export const singleDistirbutionPendingList = grantAlloDistribution + 'single-distribution-pending'
export const singleDistirbuteGrantAlloDistr = grantAlloDistribution + 'single-distribute'

const distribution = 'dae/subsidy/distribution/'
export const getApplicationDataApi = distribution + 'application'
export const getStockTotalQuantityAvailableApi = distribution + 'getstocktotalquantityavailable'
export const getApplicationInfo = distribution + 'application-info'
export const getFarmerMobileNidApi = distribution + 'get-farmer-mobile-nid-api'
export const getDistApplicantApi = distribution + 'get-dist-applicant-list'
export const getCircularInfo = distribution + 'circular-name'
export const distributionList = distribution + 'list'
export const distributionStore = distribution + 'store'

const payroll = 'dae/subsidy/payroll/'
export const getUpazillaPayrollFarmer = payroll + 'upazilla-distribution'
export const upazillaPrepare = payroll + 'upazilla-prepare'
export const upazillaApprovedPayroll = payroll + 'list'
export const getPayrollFarmer = payroll + 'payroll-farmer'
export const districtPrepare = payroll + 'district-prepare'
